<template>
  <b-card>
    <b-card-header class="d-flex justify-content-start mb-1">
      <span
        class="font-weight-bold"
        style="color: #fefefe; font-size: 16px"
      >{{ $t("NewPassword") }} </span>
    </b-card-header>
    <b-card-body>
      <div>
        <validation-observer ref="simpleRules">
          <b-form @input="changeForm()">
            <b-row>
              <b-col>
                <!-- Password -->
                <validation-provider
                  v-slot="validationContext"
                  name="password"
                  vid="Password"
                  rules="min:8|password"
                >
                  <b-form-group
                    :label="$t('administrators.pass')"
                    label-for="password"
                  >
                    <b-input-group>
                      <b-form-input
                        id="password"
                        v-model="userData.password"
                        autocomplete="new-password"
                        :type="passwordFieldType"
                        :placeholder="$t('administrators.pass')"
                        :state="getValidationState(validationContext)"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{
                      checkPassword(validationContext.failedRules)
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <!-- c-password -->
                <validation-provider
                  v-slot="validationContext"
                  name="c-password"
                  vid="c-password"
                  rules="confirmed:Password"
                >
                  <b-form-group
                    :label="$t('administrators.repass')"
                    label-for="c-password"
                  >
                    <b-input-group>
                      <b-form-input
                        id="c-password"
                        v-model="userData.cpassword"
                        autocomplete="new-password"
                        :type="passwordFieldType"
                        :placeholder="$t('administrators.repass')"
                        :state="getValidationState(validationContext)"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{
                      checkConfirmPassword(validationContext.failedRules)
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <div class="d-flex justify-content-end">
        <b-button
          variant="success"
          :disabled="disabled"
          class="mb-1 mb-sm-0  float-right mt-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="clck()"
        >
          <feather-icon icon="SaveIcon" />
          {{ $t("dataGeneric.save") }}
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>

import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'

import {
  BCard,
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  VBTooltip,
  BForm,
  BCardHeader,
  BCardBody,
  // BCardFooter,
} from 'bootstrap-vue'
import { required, email, length } from '@validations'
import Ripple from 'vue-ripple-directive' // Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import selectOptions from '@/libs/utils/v-select-from-reflection'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getUserData } from '@/auth/utils'
import i18n from '@/libs/i18n'
import { checkPermissions } from '@/store/functions'
// import userStoreModule from '../administrators/administratorsStoreModule'

const forbid = false
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    BCardBody,
    // BCardFooter,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const simpleRules = ref(null)
    const toast = useToast()
    const userData = ref({})
    const permissionGroups = ref(null)
    const selectedPermissionGroups = ref(null)
    const introspectionData = ref({})

    // const USER_APP_STORE_MODULE_NAME = 'admin-user'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //  if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })

    store
      .dispatch('admin-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data.data.allProfiles.edges[0].node

        const groups = response.data.data.allGroups.edges
        const tempArray = []
        const tempObject = {}
        groups.forEach(element => {
          tempArray.push(element.node)
        })
        permissionGroups.value = tempArray
        const userGroups = response.data.data.allProfiles.edges[0].node.customuserPtr.groups
          .edges
        selectedPermissionGroups.value = userGroups[0].node

        const loginUser = getUserData()
        /* let isSponsor = false
        if (loginUser.groups.edges.length !== 0) {
          isSponsor = loginUser.groups.edges[0].node.name === constants.sponsor
        } */

        let authorized = false
        if (!checkPermissions('users.menu_cms_profile')) {
          if (userData.value.id === loginUser.id) authorized = true
          if (!authorized) {
            router.push({ name: 'misc-not-authorized' })
          }
        }

        /* eslint-disable no-unused-vars */
        Object.entries(response.data.data.allProfiles.edges[0].node).forEach(
          ([key, value2]) => {
            /* eslint no-underscore-dangle: ["error", { "allow": ["__type"] }] */
            response.data.data.__type.fields.forEach(value => {
              if (key === value.name) {
                const mvalue = value
                mvalue.value = value2
                tempObject[key] = mvalue
              }
            })
          },
        )
        introspectionData.value = tempObject
      })
      .catch(error => {
        // console.log(error)
        if (error.status === 404) {
          userData.value = undefined
        }
      })

    const mutateUser = () => {
      simpleRules.value.validate().then(success => {
        let pass = true
        try {
          if (userData.value.cpassword === undefined) userData.value.cpassword = ''

          if (userData.value.password === undefined) userData.value.password = ''

          if (userData.value.cpassword === userData.value.password) pass = true
          else pass = false

          if (userData.value.cpassword === null) userData.value.password = null
        } catch (error) {
          // console.log(error)
        }
        if (success && pass) {
          store
            .dispatch('admin-user/mutateUser', {
              user: userData.value,
              fields: introspectionData.value,
              groups: selectedPermissionGroups.value.id,
              // TODO => AÑADIR CAMPO PARA SELECCIONAR LA QUERY A UTILIZAR
              querySelect: 'UpdateCustomuser',
            })
            .then(response => {
              // console.log('RESPONSE  => ', response)
              if (response.data.data.updateCustomuser) {
                userData.value = response.data.data.updateCustomuser.customUser
                toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t('success'),
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              } else {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: response.data.errors[0].message,
                  },
                })
              }
            })
            .catch(error => {
              // console.log(error)
              // console.log(error.response.data.errors[0])
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response.data.errors[0].message,
                },
              })
            })
        } else {
          let i = 0
          Object.keys(simpleRules.value.$data.errors).map(key => {
            if (simpleRules.value.$data.errors[key][0]) {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: simpleRules.value.$data.errors[key][0],
                },
              })
            }
            if (!pass && i === 0) {
              i += 1
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: i18n.t('confirmPassword'),
                },
              })
            }
            return key
          })
        }
      })
    }
    const blankUserData = {
      password: '',
      cpassword: '',
    }

    const userDataBlank = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userDataBlank.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const { getValidationState } = formValidation(resetuserData)
    return {
      userData,
      getValidationState,
      permissionGroups,
      selectedPermissionGroups,
      introspectionData,
      selectOptions,
      mutateUser,
      simpleRules,
    }
  },
  data() {
    return {
      email,
      forbid,
      required,
      length,
      loginUser: getUserData(),
      disabled: true,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  /* beforeDestroy() {
    if (store.hasModule('admin-user')) store.unregisterModule('admin-user')
  },
  beforeCreate() {
    if (!store.hasModule('admin-user')) store.registerModule('admin-user', userStoreModule)
  }, */
  methods: {
    checkConfirmPassword(rules) {
      if (rules.confirmed) {
        return (this.$t('confirmPassword'))
      }
      return ''
    },
    changeForm() {
      this.disabled = false
    },
    checkPassword(rules) {
      if (rules.min) {
        return (this.$t('pass8Cha'))
      }
      if (rules.password) {
        return (this.$t('passMayus'))
      }
      return ''
    },
    checkEmail(rules) {
      if (rules.email) {
        return (this.$t('verifiedEmail'))
      }
      return ''
    },
    clck() {
      this.mutateUser()
      this.disabled = !this.disabled
    },
  },
}
</script>
