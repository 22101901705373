<template>
  <div>
    <b-card>
      <b-card-header class="d-flex justify-content-start mb-1">
        <span
          class="font-weight-bold"
          style="color: #fefefe; font-size: 16px"
        >{{ $t("administrators.usIn") }} </span>
      </b-card-header>
      <b-card-body>
        <div>
          <div>
            <div class="d-flex justify-content-start ">
              <!-- Antigua IMAGEN  -->
              <b-img
                v-if="imageSrc || userData.avatar"
                ref="previewEl"
                rounded
                height="125"
                style="background-color: #31375a;"
                :src="imageSrc ? imageSrc : userData.avatar"
                @error="handleImageUserError"
              />
              <b-img
                v-else
                ref="previewEl"
                rounded
                height="125"
                style="background-color: #31375a;"
                :src="srcImageCard"
                @error="handleImageUserError"
              />
              <div class="d-flex align-items-end pl-1 ">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mr-75"
                  @click="selectFile"
                >
                  <feather-icon
                    icon="UploadIcon"
                    size="16"
                  />
                  {{ $t("media.upload") }}
                </b-button>
                <b-form-file
                  ref="refInputEl"
                  v-model="imageData"
                  accept="image/jpeg, image/png"
                  :hidden="true"
                  plain
                  @change="handleFileChange"
                  @input="changeForm()"
                />
                <!-- <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-danger"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="deleteFile()"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                  />
                  Eliminar
                </b-button> -->
                <feather-icon
                  v-b-tooltip.hover.right="infoImg"
                  icon="InfoIcon"
                  size="18"
                  class="cursor-help"
                  style="margin-bottom: 6px;"
                />
              </div>
            </div>
          </div>
          <hr>
          <div>
            <validation-observer ref="simpleRules">
              <b-form
                class="mt-2"
                @input="changeForm()"
              >
                <b-row>
                  <b-col sm="6">
                    <b-form-group
                      :label="$t('administrators.user')"
                      label-for="mc-user-name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="User Name"
                        rules="required"
                      >
                        <b-form-input
                          id="mc-user-name"
                          v-model="userData.username"
                          disabled
                          autocomplete="new-password"
                          :placeholder="$t('administrators.user')"
                          maxlength="75"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      :label="$t('administrators.email')"
                      label-for="mc-user-email"
                    >
                      <validation-provider
                        v-slot="validationContext"
                        name="User Email"
                        rules="email"
                      >
                        <b-form-input
                          id="mc-user-email"
                          v-model="userData.email"
                          autocomplete="new-password"
                          :placeholder="$t('administrators.email')"
                          maxlength="254"
                          :state="getValidationState(validationContext)"
                        />
                        <small class="text-danger">{{
                          checkEmail(validationContext.failedRules)
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      :label="$t('administrators.name')"
                      label-for="mc-first-name"
                    >
                      <b-form-input
                        id="mc-first-name"
                        v-model="userData.firstName"
                        :placeholder="$t('administrators.name')"
                        autocomplete="new-password"
                        maxlength="30"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      :label="$t('administrators.lname')"
                      label-for="mc-last-name"
                    >
                      <b-form-input
                        id="mc-last-name"
                        v-model="userData.lastName"
                        autocomplete="new-password"
                        :placeholder="$t('administrators.lname')"
                        maxlength="30"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      :label="$t('resources.tableHeader.description')"
                      label-for="mc-description"
                    >
                      <b-form-textarea
                        id="mc-description"
                        v-model="userData.description"
                        placeholder="Introduce la descripción"
                        rows="3"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
          <div class="d-flex justify-content-end">
            <b-button
              variant="success"
              :disabled="disabled"
              class="mb-1 mb-sm-0  float-right mt-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="clck()"
            >
              <feather-icon icon="SaveIcon" />
              {{ $t("dataGeneric.save") }}
            </b-button>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { useToast } from 'vue-toastification/composition'
import { getUserData } from '@/auth/utils'
import selectOptions from '@/libs/utils/v-select-from-reflection'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import {
//   base64Encode,
// } from '@/store/functions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BImg,
  VBTooltip,
  BFormTextarea,
  BCardHeader,
  BCardBody,

} from 'bootstrap-vue'
import { checkPermissions } from '@/store/functions'
// import userStoreModule from '../administrators/administratorsStoreModule'
const noImgUser = require('@/assets/images/backend/user.svg')

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BCardHeader,
    BCardBody,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const simpleRules = ref(null)
    const toast = useToast()
    const userData = ref({})
    const permissionGroups = ref(null)
    const selectedPermissionGroups = ref(null)
    const introspectionData = ref({})
    const imageData = ref(null)
    // const USER_APP_STORE_MODULE_NAME = 'admin-user'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })

    store
      .dispatch('admin-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data.data.allProfiles.edges[0].node
        // // console.log('userData.value => ', userData.value)
        const groups = response.data.data.allGroups.edges
        const tempArray = []
        const tempObject = {}
        groups.forEach(element => {
          tempArray.push(element.node)
        })
        permissionGroups.value = tempArray
        const userGroups = response.data.data.allProfiles.edges[0].node.customuserPtr.groups
          .edges
        selectedPermissionGroups.value = userGroups[0].node

        const loginUser = getUserData()

        let authorized = false
        if (!checkPermissions('users.menu_cms_profile')) {
          if (userData.value.id === loginUser.id) authorized = true
          if (!authorized) {
            router.push({ name: 'misc-not-authorized' })
          }
        }

        /* eslint-disable no-unused-vars */
        Object.entries(response.data.data.allProfiles.edges[0].node).forEach(
          ([key, value2]) => {
            /* eslint no-underscore-dangle: ["error", { "allow": ["__type"] }] */
            response.data.data.__type.fields.forEach(value => {
              if (key === value.name) {
                const mvalue = value
                mvalue.value = value2
                tempObject[key] = mvalue
              }
            })
          },
        )
        introspectionData.value = tempObject
      })
      .catch(error => {
        // console.log(error)
        if (error.status === 404) {
          userData.value = undefined
        }
      })

    const mutateUser = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // console.log('imageData.value => ', imageData.value)
          store
            .dispatch('admin-user/mutateUser', {
              user: userData.value,
              fields: introspectionData.value,
              groups: selectedPermissionGroups.value.id,
              description: userData.value.description,
              avatar: imageData.value,
              querySelect: 'UpdateProfile',
              view: 'Profile',

            })
            .then(response => {
              // TODO => REALIZAR ESTO MISMO CON TODOS LOS QUE USEN LA QUERY PRO
              // // console.log('RESPONSE  => ', response)
              if (response.data.data.updateProfile) {
                // console.log('response.data.data.updateProfile.profile => ', response.data.data.updateProfile.profile)
                userData.value = response.data.data.updateProfile.profile
                toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t('success'),
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              } else {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: response.data.errors[0].message,
                  },
                })
              }
            })
            .catch(error => {
              // // console.log(error)
              // // console.log(error.response.data.errors[0])
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response.data.errors[0].message,
                },
              })
            })
        } else {
          let i = 0
          Object.keys(simpleRules.value.$data.errors).map(key => {
            if (simpleRules.value.$data.errors[key][0]) {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: simpleRules.value.$data.errors[key][0],
                },
              })
            }
            if (i === 0) {
              i += 1
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: i18n.t('confirmPassword'),
                },
              })
            }
            return key
          })
        }
      })
    }
    const blankUserData = {
      password: '',
      cpassword: '',
    }

    const userDataBlank = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userDataBlank.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const { getValidationState } = formValidation(resetuserData)
    return {
      userData,
      getValidationState,
      permissionGroups,
      selectedPermissionGroups,
      introspectionData,
      selectOptions,
      mutateUser,
      simpleRules,
      imageData,
    }
  },
  data() {
    return {
      srcImageCard: noImgUser,
      required,
      disabled: true,
      loginUser: getUserData(),
      infoImg: this.$t('allowImage2'),
      fileUrl: null,
      profileFile: null, // Archivo seleccionado
      uploadedUrl: null, // URL de la imagen subida
      imgNoDisponible: true,
      imageSrc: null,
    }
  },
  watch: {

    imageOrigin() {
      this.imageDataUrl = this.imageOrigin
    },
  },

  methods: {
    /* resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    }, */
    handleImageUserError(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.src = noImgUser
      // this.imgNoDisponible = true
    },
    checkEmail(rules) {
      if (rules.email) {
        return (this.$t('verifiedEmail'))
      }
      return ''
    },
    changeForm() {
      this.disabled = false
    },
    clck() {
      this.mutateUser()
      this.disabled = !this.disabled
    },
    // Método para abrir el selector de archivos
    selectFile() {
      // Accede al elemento HTML nativo del input de archivo y activa click()
      this.$refs.refInputEl.$el.click()
    },
    handleFileChange(event) {
      // console.log('handleFileChange')
      const file = event.target.files[0] // Accede al primer archivo seleccionado
      if (file) {
        // Crea un objeto URL para la imagen y la almacena en la variable 'image'
        this.imageSrc = URL.createObjectURL(file)
        // console.log('imageSrc => ', this.imageSrc)
        // Si necesitas almacenar el archivo de la imagen para otros fines, puedes usar `imageData`
        // this.imageData = file
      }
    },

    // Método para generar la URL del archivo seleccionado
    generateFileUrl(event) {
      const file = event.target.files[0]
      // console.log('file => ', file)
      if (file) {
        // Crear una URL para mostrar la imagen seleccionada
        this.fileUrl = URL.createObjectURL(file)
        // console.log('fileUrl => ', this.fileUrl)
      }
    },
    // TODO => Borrar imagen
  },
}
</script>
<style scoped lang="scss"></style>
