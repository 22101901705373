<template>
  <section id="final-users-v2">
    <b-row>
      <b-col>
        <h1>{{ $t("Profile") }}</h1>
        <h5 class="text-primary">
          {{ $t("editProfile") }}
        </h5>
      </b-col>
      <b-col>
        <div class="d-flex justify-content-end">
          <router-link to="/dashboard">
            <b-button variant="danger">
              {{ $t("dataGeneric.cancel") }}
            </b-button>
          </router-link>
        </div>
      </b-col>
    </b-row>
    <div class="mt-2">
      <b-tabs
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t("User") }}</span>
          </template>

          <account-setting-general />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="LockIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t('changPass') }}</span>
          </template>

          <account-setting-password />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t("administrators.info") }}</span>
          </template>

          <account-setting-information />
        </b-tab>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import {
  BTabs,
  BTab,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue'
import store from '@/store'
import AccountSettingGeneral from '../setting/UserSettingGeneral.vue'
import AccountSettingPassword from '../setting/UserSettingPassword.vue'
import AccountSettingInformation from '../setting/UserSettingInformation.vue'
import userStoreModule from '../administrators/administratorsStoreModule'

export default {
  components: {
    BTabs,
    BTab,
    BCol,
    BRow,
    BButton,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
  },
  beforeDestroy() {
    if (store.hasModule('admin-user')) store.unregisterModule('admin-user')
    // console.log('General Profile - beforeDestroy')
  },
  beforeCreate() {
    if (!store.hasModule('admin-user')) store.registerModule('admin-user', userStoreModule)
    // console.log('General Profile - beforeCreate')
  },
}
</script>
