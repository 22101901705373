<template>
  <b-card>
    <b-card-header class="d-flex justify-content-start">
      <span
        class="font-weight-bold"
        style="color: #fefefe; font-size: 16px"
      >{{ $t('ActvDetail') }}</span>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('administrators.date')"
            label-for="mc-dateJoined-name"
          >
            <strong>
              {{ checkDate(userData.dateJoined) }}
            </strong>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('administrators.last')"
            label-for="mc-lastLogin-name"
          >
            <strong>
              {{ checkDate(userData.lastLogin) }}
            </strong>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { useToast } from 'vue-toastification/composition'
import { getUserData } from '@/auth/utils'
import selectOptions from '@/libs/utils/v-select-from-reflection'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'
import {
  BFormGroup,
  BRow,
  BCol,
  BCard,
  VBTooltip,
  BCardHeader,
  BCardBody,

} from 'bootstrap-vue'
import { checkPermissions } from '@/store/functions'
// import userStoreModule from '../administrators/administratorsStoreModule'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const simpleRules = ref(null)
    const toast = useToast()
    const userData = ref({})
    const permissionGroups = ref(null)
    const selectedPermissionGroups = ref(null)
    const introspectionData = ref({})

    // const USER_APP_STORE_MODULE_NAME = 'admin-user'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })

    store
      .dispatch('admin-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data.data.allProfiles.edges[0].node

        const groups = response.data.data.allGroups.edges
        const tempArray = []
        const tempObject = {}
        groups.forEach(element => {
          tempArray.push(element.node)
        })
        permissionGroups.value = tempArray
        const userGroups = response.data.data.allProfiles.edges[0].node.customuserPtr.groups
          .edges
        selectedPermissionGroups.value = userGroups[0].node

        const loginUser = getUserData()
        /* let isSponsor = false
        if (loginUser.groups.edges.length !== 0) {
          isSponsor = loginUser.groups.edges[0].node.name === constants.sponsor
        } */

        let authorized = false

        if (!checkPermissions('users.menu_cms_profile')) {
          if (userData.value.id === loginUser.id) authorized = true
          if (!authorized) {
            router.push({ name: 'misc-not-authorized' })
          }
        }

        /* eslint-disable no-unused-vars */
        Object.entries(response.data.data.allProfiles.edges[0].node).forEach(
          ([key, value2]) => {
            /* eslint no-underscore-dangle: ["error", { "allow": ["__type"] }] */
            response.data.data.__type.fields.forEach(value => {
              if (key === value.name) {
                const mvalue = value
                mvalue.value = value2
                tempObject[key] = mvalue
              }
            })
          },
        )
        introspectionData.value = tempObject
      })
      .catch(error => {
        // console.log(error)
        if (error.status === 404) {
          userData.value = undefined
        }
      })

    const mutateUser = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          store
            .dispatch('admin-user/mutateUser', {
              user: userData.value,
              fields: introspectionData.value,
              groups: selectedPermissionGroups.value.id,
              // TODO => AÑADIR CAMPO PARA SELECCIONAR LA QUERY A UTILIZAR
              // TODO => AÑADIR CAMPO PARA SELECCIONAR LA VISTA Y EN CONSECUENCIA SI AÑADIR O NO EL SUBGRUPO

            })
            .then(response => {
              // console.log('RESPONSE  => ', response)
              if (response.data.data.updateProfile) {
                userData.value = response.data.data.updateProfile.profile
                toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t('success'),
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              } else {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: response.data.errors[0].message,
                  },
                })
              }
            })
            .catch(error => {
              // console.log(error)
              // console.log(error.response.data.errors[0])
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response.data.errors[0].message,
                },
              })
            })
        } else {
          let i = 0
          Object.keys(simpleRules.value.$data.errors).map(key => {
            if (simpleRules.value.$data.errors[key][0]) {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: simpleRules.value.$data.errors[key][0],
                },
              })
            }
            if (i === 0) {
              i += 1
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: i18n.t('confirmPassword'),
                },
              })
            }
            return key
          })
        }
      })
    }
    const blankUserData = {
      password: '',
      cpassword: '',
    }

    const userDataBlank = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userDataBlank.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const { getValidationState } = formValidation(resetuserData)
    return {
      userData,
      getValidationState,
      permissionGroups,
      selectedPermissionGroups,
      introspectionData,
      selectOptions,
      mutateUser,
      simpleRules,
    }
  },
  data() {
    return {
    }
  },
  /* beforeDestroy() {
    if (store.hasModule('admin-user')) store.unregisterModule('admin-user')
  },
  beforeCreate() {
    if (!store.hasModule('admin-user')) store.registerModule('admin-user', userStoreModule)
  }, */
  methods: {
    checkDate(value) {
      // Verifica si el valor es nulo, indefinido o si el objeto Date es inválido
      if (!value || Number.isNaN(new Date(value).getTime())) {
        return 'Fecha no válida'
      }

      // Construye el idioma con la configuración de i18n
      const language = `${this.$i18n.locale}-${this.$i18n.locale.toUpperCase()}`

      // Crea el objeto Date
      const date = new Date(value)

      // Configura las opciones de formato
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }

      // Formatea la fecha con Intl.DateTimeFormat
      const dateFormat = new Intl.DateTimeFormat(language, options)
      return dateFormat.format(date)
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
